<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="isDeletePage() != true ? 'Skus' : 'Deleted Skus'"></h3>
              <template v-if="isDeletePage() != true">
                <v-btn class="mx-2 mb-2" fab x-small color="primary" to="/skus/create">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... " color="#e60040"
                    prepend-inner-icon="mdi-magnify" clearable @click:clear="
                (filter.search_keyword = null), (page = 1), getData()
                " @keydown.enter="(page = 1), getData()">
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="status" solo rounded v-model="filter.is_active" :items="statuses" clearable
                    @click:clear="(filter.is_active = null), (page = 1)" label="Status" @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sr No</th>
                      <th class="text-left">Sku Name</th>
                      <th class="text-left">Brand</th>
                      <th class="text-left">Hsn Code</th>
                      <th class="text-left">MRP</th>
                      <th class="text-left">Image</th>
                      <th class="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="skus.length != 0">
                    <tr v-for="(sku, i) in skus" :key="`sku${i}`">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>{{ sku.name || "" }}</td>
                      <td>{{ sku.brand ? sku.brand.description : "" }}</td>
                      <td>{{ sku.hsn_code || "" }}</td>
                      <td>{{ sku.price || "" }}</td>
                      <td>
                        <a v-if="sku.image_path" target="_blank" :href="`${mediaUrl}${sku.image_path}`">
                          <v-img class="mx-auto" :src="`${mediaUrl}${sku.image_path}`" width="50"></v-img>
                        </a>
                      </td>

                      <td class="text-right">
                        <v-btn class="mx-2" fab x-small color="primary" :to="`/skus/${encryptIt(sku.id)}`">
                          <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_sku" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Sku deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_sku = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Restore Success Dialog Box -->
      <v-dialog v-model="is_restored_sku" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-restore
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Sku restored successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_restored_sku = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "skusPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      skus: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      ExportSkuTypes: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
      },
      isLoading: false,
      isExcelloading: false,
      delete_sku: false,
      is_deleted_sku: false,
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`skus?${query}`)
        .then((response) => {
          this.skus = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    //Generate Excel
    async generateExcel() {
      let query = "search_keyword=" + this.filter.search_keyword;
      let response = await axios.get(`sku_exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },
    async Delete_this(id) {
      this.delete_sku = true;
      await axios
        .post(`/skus/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_sku = false;
          this.is_deleted_sku = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async Restore_this(id) {
      this.restore_sku = true;
      await axios
        .post(`/skus/restore/${this.encryptIt(id)}`)
        .then(() => {
          this.restore_sku = false;
          this.is_restored_sku = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>